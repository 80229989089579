import React, { useEffect, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import GPT from '../images/GPT.jpg';
import user from '../images/user.jpg'
import { TypeAnimation } from 'react-type-animation';
import CircularProgress from '@mui/material/CircularProgress';

export default function MessageList({
  Questionsanswers,
  currentQnA,
  question,
  loading,
  isMobile,
  setCurrentQnA
}) {
  const bottomRef = useRef(null);

  useEffect(() => {
    console.log('scrolling to bottom')
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [question]);
  return (
    <List sx={{ height: '75vh', overflowY: 'scroll', paddingTop: isMobile ? 5 : 10, }} className='message-list'>
      {Questionsanswers.length !== 0 &&
        Questionsanswers.map(({ Question, Answer }, index) => {
          if (Question !== currentQnA.Question) {
            return (
              <div key={index}>
                <ListItem
                  sx={{ py: isMobile ? 1 : 3, px: isMobile ? 5 : 20, }}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant='rounded'
                      alt='Profile Picture'
                      src={user}
                    />
                  </ListItemAvatar>
                  <ListItemText secondary={Question} />
                </ListItem>
                <Divider />
                <ListItem
                  key={index + 500}
                  sx={{ py: isMobile ? 1 : 3, px: isMobile ? 5 : 20, marginBottom: '50px' }}
                >
                  <ListItemAvatar>
                    <Avatar variant='rounded' alt='Profile Picture' src={GPT} />
                  </ListItemAvatar>
                  <ListItemText secondary={Answer} />
                </ListItem>
                <Divider />
              </div>
            );
          }
          return null;
        })}
      {question && (
        <>
          <ListItem sx={{ py: isMobile ? 1 : 3, px: isMobile ? 5 : 20 }}>
            <ListItemAvatar>
              <Avatar variant='rounded' alt='Profile Picture' src={user} />
            </ListItemAvatar>
            <ListItemText secondary={question || currentQnA.Question} />
          </ListItem>
          <Divider />
        </>
      )}
      {loading &&
        <>
          <ListItem button sx={{ py: isMobile ? 1 : 3, px: isMobile ? 5 : 20 }}>
            <ListItemAvatar>
              <Avatar variant='rounded' alt='Profile Picture' src={GPT} />
            </ListItemAvatar>
            <CircularProgress color='inherit' />
          </ListItem>
          <Divider />
        </>
      }
      {currentQnA.Question && (
        <>
          <ListItem sx={{ py: isMobile ? 1 : 3, px: isMobile ? 5 : 20 }}>
            <ListItemAvatar>
              <Avatar variant='rounded' alt='Profile Picture' src={user} />
            </ListItemAvatar>
            <ListItemText secondary={currentQnA.Question} />
          </ListItem>
          <Divider />
          <ListItem sx={{ py: isMobile ? 1 : 3, px: isMobile ? 5 : 20, marginBottom: '50px', position: 'relative', bottom: '0' }}>
            <ListItemAvatar>
              <Avatar variant='rounded' alt='Profile Picture' src={GPT} />
            </ListItemAvatar>
            {!loading && (
              <ListItemText
                secondary={
                  <TypeAnimation
                    sequence={[currentQnA.Answer, () => {
                      setCurrentQnA({})
                    }]}
                    wrapper='div'
                    cursor={false}
                    repeat={0}
                    speed={70}
                  />
                }
              />
            )}

          </ListItem>
          <Divider />
        </>
      )
      }
      <div ref={bottomRef} ></div>
    </List >
  );
}

import './App.css';
// Import the functions you need from the SDKs you need
// // TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


import ChatGPT from './Screens/ChatGPT'
function App() {

  return (
    <div className="App">
      <ChatGPT />
    </div>
  );
}

export default App;

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import GPT from '../images/GPT.jpg'
import { useState, useEffect, useRef } from 'react';
import { Toolbar, Typography, Link } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import ColorMode from '../Components/ColorMode';
import MessageList from './../Components/MessageList';
import MessageInput from '../Components/MessageInput';

const drawerWidth = 240;

export default function ChatGPT() {
  const theme = useTheme();
  const [mode, setMode] = React.useState('light');
  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };
  const colorTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );
  const [AllData, setAllData] = useState([{
    id: 1, data: [
      {
        question: 'question 1',
        answer: 'answer 1'
      },
      {
        question: 'question 2',
        answer: 'answer 2'
      },
      {
        question: 'question 3',
        answer: 'answer 3'
      },
    ]
  }, {
    id: 2, data: [
      {
        question: 'question 1',
        answer: 'answer 1'
      },
      {
        question: 'question 2',
        answer: 'answer 2'
      },
      {
        question: 'question 3',
        answer: 'answer 3'
      },
    ]
  }]);
  const [open, setOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [question, setQuestion] = useState();
  const [Questionsanswers, setQuestionsanswers] = useState([]);
  const [isMobile, setIsMobile] = useState(true);
  const [currentQnA, setCurrentQnA] = useState([]);

  const addNewChat = () => {
    let id1 = AllData.length + 1
    let newChat = { id: id1, ChatData: [] }
    setAllData([...AllData, newChat])
  }

  const addnewQuestion = (question) => {
    console.log("addnewQuestion", question);
    setCurrentQnA({ question: question })
    getReplyFromOpenAI(question);
  };

  const getReplyFromOpenAI = async (question) => {
    let isSubscribed = true;
    setLoading(true)
    setQuestion(question);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ Query: question })
    }
    const data = await fetch('https://chatapi.toppbolig.no', requestOptions)
      .then(response => response.json()).catch(err => {
        let error1 = err.message
      });

    if (isSubscribed) {
      setLoading(false)
    }
    let newQuestionsanswers = { Question: question, Answer: data.answer }
    setQuestionsanswers([...Questionsanswers, newQuestionsanswers]);
    setCurrentQnA(newQuestionsanswers)
    setQuestion("");
  }

  useEffect(() => {
    if (window.innerWidth > 700) {
      handleDrawerOpen();
      setIsMobile(false)
    }
  }, [])



  const clearALL = () => {
    // clear all function goes here
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));


  return (
    <ThemeProvider theme={colorTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{ background: 'transparent', boxShadow: 'none' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="end"
              sx={{ ...(open && { display: 'none' }) }}
            >
              <MenuIcon color='primary' />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          PaperProps={{
            sx: {
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Box sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
          }}>
            <img src={GPT} className='app-logo' />
            <>
              <Link sx={{
                display: 'flex',
                alignItems: 'center',
              }} underline='none' color='inherit' href='https://www.toppbolig.no'
                target='_blank'><HomeIcon color='inherit' />Tilbake til fremside</Link>
            </>
          </Box>
          <Box sx={{
            position: 'absolute',
            bottom: '50px',
            width: '100%',
          }}>
            <Divider />
            <ColorMode toggleColorMode={toggleColorMode} />
            <a href='http://www.toppbolig.no/' target='_blank'><Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              bgcolor: 'background.default',
              color: 'text.primary',
              borderRadius: 1,
              p: 3,
              paddingTop: '0px'
            }}>
              <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
              <Typography sx={{ ml: 1 }}>Boliger til salgs</Typography>
            </Box></a>

          </Box>
        </Drawer>

        <Main open={open} sx={{
          overflowX: 'hidden',
          position: 'relative',
          height: '100%',
          padding: '0px',
        }}>
          <MessageList Questionsanswers={Questionsanswers} currentQnA={currentQnA} isMobile={isMobile} loading={Loading} question={question} setCurrentQnA={setCurrentQnA} key='message-list' />

          <MessageInput addnewQuestion={addnewQuestion} isMobile={isMobile} loading={Loading} />

        </Main>

      </Box>
    </ThemeProvider>
  );
}

import { Button, Paper, Typography } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import React from 'react'
import SendIcon from '@mui/icons-material/Send';

export default function MessageInput({ handleChange, addnewQuestion, isMobile, loading }) {
  const [input, setInput] = React.useState('')

  const handleOnChange = (event) => {
    event.preventDefault()
    setInput(event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addnewQuestion(input);
    setInput('');
  }
  return (

    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: isMobile ? '5%' : '0 20% 0 20%' }} >

      <Paper
        component="form"
        sx={{ p: '2px 8px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', alignContent: 'center', marginTop: '10px' }}
        onSubmit={e => handleSubmit(e)}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={(event) => handleOnChange(event)}
          value={input}
          required={true}
          disabled={loading}
          placeholder='Hei! Hva ønsker du å spørre oss om? '
        />
        <Button disabled={input.length === 0} type="button" sx={{ p: '10px' }} aria-label="search" onClick={e => handleSubmit(e)} >
          <SendIcon color='disabled' />
        </Button>
      </Paper>
      <Typography variantMapping='p' sx={{
        fontSize: '.75rem',
        marginTop: '10px',
        marginBottom: '10px',
      }}>Topp Bolig AS Chatbot. Målet vårt er å hjelpe deg som kunde til å få god hjelp, samt oppleve en god kundeopplevelse med oss.<br /> Tilbakemeldingen din vil hjelpe oss å forbedre oss.</Typography>
    </Paper>

  )
}
